import React, { FunctionComponent } from "react"
import secondsIcon from "../images/seconds-pro-icon.svg"
import Hero from "../components/hero"
import AppStore from "../components/appstore"
import PlayStore from "../components/playstore"
import { graphql, Link, PageProps } from "gatsby"
import Layout from "../layouts/layout"
import Feature from "../components/feature"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import bodycoachLogo from "../images/bodycoach_logo_blue.svg"
import {
  fixedWithIdentifier,
  fluidWithIdentifier,
} from "../helpers/image-helpers"
import { MetadataProps, SEOProps } from "../types/types"
import SEO from "../components/seo"
import FavIcons from "../components/fav-icons"

const SecondsPage: FunctionComponent<PageProps<MetadataProps & SEOProps>> = ({
  data,
}) => {
  const deviceFrame = fixedWithIdentifier("deviceFrame", data)
  return (
    <Layout>
      <SEO
        title="Seconds Interval Timer app for iOS & Android"
        description="Seconds Interval Timer includes templates for HIIT, Tabata
            and circuit training, text-to-speech, music per interval, vibrate
            full screen display and much more."
        image={data.seoImage.childImageSharp.sizes.src}
        favIconSizes={[16, 32, 48, 96]}
        favIconTemplate="/images/seconds/favicon-%s.png"
      />

      <Hero
        fluidImage={fluidWithIdentifier("hero", data)}
        logo={secondsIcon}
        logoAlt="Seconds Pro Icon"
        heading="Seconds Interval Timer"
        lead="The best interval timer app for HIIT, Tabata and circuit training
              workouts."
      >
        <AppStore url={data.site.siteMetadata.urls.seconds.appStore} />{" "}
        <PlayStore url={data.site.siteMetadata.urls.seconds.googlePlay} />
      </Hero>
      <div style={{ backgroundColor: "#eceeef" }}>
        <Container>
          <Row className="pt-4">
            <Col>
              <h4>
                <img src={bodycoachLogo} alt="The Body Coach" />
              </h4>
              <p className="lead">
                Find out why{" "}
                <Link to="/seconds-interval-timer/joe-wicks-body-coach">
                  Joe Wicks uses Seconds Interval Timer
                </Link>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Feature
        title="HIIT, Tabata and Circuit Training"
        screenshot={fixedWithIdentifier("templates", data)}
        frame={deviceFrame}
      >
        <p>
          Seconds comes with templates for common interval programming allowing
          to build advanced timers in a matter of moments.
        </p>
        <p>
          If the templates do not fit your needs you can use the custom timer to
          build your timer one interval at a time. You can even combine and loop
          through many timers using the compound timer.
        </p>
      </Feature>

      <Feature
        title="Speaks Your Interval Names"
        screenshot={fixedWithIdentifier("textToSpeech", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          Often when working out you just want to keep your phone in your pocket
          and get on with it. But if you are doing a circuit involving many
          intervals how do you remember what's next?
        </p>
        <p>
          Seconds will speak your interval names, so this is never a problem.
          There is even an option to let you know what the next interval is so
          you have time to prepare before it starts.
        </p>
      </Feature>

      <Feature
        title="Soothing Alerts"
        screenshot={fixedWithIdentifier("otherAlerts", data)}
        frame={deviceFrame}
      >
        <p>
          If you are looking for a mediation or yoga timer text-to-speech or
          high pitch beeps may not be appropriate. For situations like these
          Seconds comes with more soothing alerts such as bells and gongs, or
          you may wish to have no alert sounds at all, or just a vibration. All
          these options are available.
        </p>
      </Feature>

      <Feature
        title="Music Options"
        screenshot={fixedWithIdentifier("music", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          Seconds provides plenty of options for how you listen to your music
          while you workout. A few options include:
        </p>
        <ul>
          <li>Assign songs to intervals</li>
          <li>Play a playlist throughout a set</li>
          <li>No music during your breaks</li>
          <li>Shuffled album throughout the entire timer</li>
        </ul>
        <p>
          Of course, if none of those suit you, you can simply listen to your
          favourite music player in the background and Seconds will play its
          alerts over the top.
        </p>
        <small>Not all these features are available on Android.</small>
      </Feature>

      <Feature
        title="Import and Export"
        screenshot={fixedWithIdentifier("importExport", data)}
        frame={deviceFrame}
      >
        <p>
          Importing and exporting timers from Seconds Pro could not be simpler.
          Just select the timers you wish to share and hit Export. Timers can be
          sent via email or AirDrop (on iOS devices).
        </p>
        <p>
          Timers can even be shared with friends that don't have the app via the
          web sharing function. Once shared, an online timer will be created for
          anyone to use on any device.
        </p>
      </Feature>

      <Feature
        title="Foreground and Background"
        screenshot={fixedWithIdentifier("background", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          Seconds Interval Timer will continue to alert you of interval changes
          whether in the background or the foreground.
        </p>
      </Feature>

      <Feature
        title="Interval Colors"
        screenshot={fixedWithIdentifier("colours", data)}
        frame={deviceFrame}
      >
        <p>
          Colored intervals are great when you just need a visual clue to see
          which interval you are on and what is coming up next. Seconds displays
          the the whole screen in the current intervals color, apart from the
          next interval which uses its own color.
        </p>
        <p>
          Seconds has 10 colors to choose from with an advanced option to
          customize the colors if you wish.
        </p>
      </Feature>

      <Feature
        title="Vertical or Horizontal Display"
        screenshot={fixedWithIdentifier("orientation", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          Seconds utilises all the available width for the main timer display so
          it's easy to read at a glance. By default it displays the interval
          time remaining, but can be configured to show total time remaining, or
          elapsed times.
        </p>
      </Feature>
    </Layout>
  )
}

export default SecondsPage

export const query = graphql`
  fragment fixedScreenshot on File {
    childImageSharp {
      fixed(width: 243, height: 432) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fragment fluidBackground on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query SecondsQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    seoImage: file(relativePath: { eq: "images/seconds/promo.jpg" }) {
      childImageSharp {
        sizes {
          src
        }
      }
    }
    hero: file(relativePath: { eq: "images/seconds/seconds-background.jpg" }) {
      ...fluidBackground
    }
    deviceFrame: file(
      relativePath: { eq: "images/device-frames/iphone-6-white.png" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 582) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    background: file(
      relativePath: { eq: "images/seconds/screenshots/background.jpg" }
    ) {
      ...fixedScreenshot
    }
    colours: file(
      relativePath: { eq: "images/seconds/screenshots/colours.jpg" }
    ) {
      ...fixedScreenshot
    }
    textToSpeech: file(
      relativePath: { eq: "images/seconds/screenshots/text-to-speech.jpg" }
    ) {
      ...fixedScreenshot
    }
    otherAlerts: file(
      relativePath: { eq: "images/seconds/screenshots/other-alerts.jpg" }
    ) {
      ...fixedScreenshot
    }
    music: file(relativePath: { eq: "images/seconds/screenshots/music.jpg" }) {
      ...fixedScreenshot
    }
    importExport: file(
      relativePath: { eq: "images/seconds/screenshots/import-export.jpg" }
    ) {
      ...fixedScreenshot
    }
    orientation: file(
      relativePath: { eq: "images/seconds/screenshots/orientation.jpg" }
    ) {
      ...fixedScreenshot
    }
    templates: file(
      relativePath: { eq: "images/seconds/screenshots/templates.jpg" }
    ) {
      ...fixedScreenshot
    }
  }
`
