import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img, { FixedObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import Rule from "./rule"

interface Props {
  title: string
  screenshot: FixedObject
  frame: FixedObject
  altLayout?: boolean
}

const Feature: FunctionComponent<Props> = props => {
  const textPos = props.altLayout ? 1 : 0
  const screenPos = props.altLayout ? 0 : 1

  return (
    <div>
      <Container className="py-3 py-sm-5">
        <Row className="align-items-center">
          <Col
            sm={{ span: 4, order: textPos }}
            md={{ span: 6, order: textPos }}
            lg={{ span: 8, order: textPos }}
          >
            <h2>{props.title}</h2>
            {props.children}
          </Col>
          <Col
            sm={{ span: 8, order: screenPos }}
            md={{ span: 6, order: screenPos }}
            lg={{ span: 4, order: screenPos }}
          >
            <div className="mx-auto ml-sm-0 mr-sm-auto">
              <div
                style={{
                  position: "relative",
                }}
              >
                <Img
                  style={{
                    position: "relative",
                    margin: "70px 29px 80px 28px",
                  }}
                  fixed={props.screenshot}
                />
                <Img
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: -1,
                  }}
                  fixed={props.frame}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Rule />
    </div>
  )
}

export default Feature
